<template>
  <div class="bpmn">
    <bpmn></bpmn>
  </div>
</template>

<script>
import bpmn from '@/components/bpmnBuilder'
export default {
  name: 'bpmnPage',
  data () {
    return {
      example: ''
    }
  },
  components: {
    bpmn
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
</style>
